<template>
    <v-card hover class="ma-0 pa-0">
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">주소록 추가하기</v-col>
            </v-row>
        </v-card-title>

        <v-card-text>
            <v-row class="align-center mt-0 pa-0">
                <v-col class="align-center text-left">
                    <v-card outlined color="#C9C9C9">
                        <v-card-text class="ma-0 pa-3" style="letter-spacing: 1px;">
                            <ul>
                                <li style="font: normal normal bold 13px/20px Apple SD Gothic Neo;" class="red--text">'연락처' 항목의 형식을 텍스트로 설정하여 '0'이 생략되지 않도록 해주세요.</li>
                                <li style="font: normal normal bold 13px/20px Apple SD Gothic Neo;" class="red--text">'연락처' 항목에는 숫자만 입력해 주세요.</li>
                                <li style="font: normal normal normal 13px/20px Apple SD Gothic Neo;">최대 <span class="font-weight-bold blue--text">1만 건</span> 등록할 수 있습니다.</li>
                                <li style="font: normal normal normal 13px/20px Apple SD Gothic Neo;">'이름' 항목은 최대 <span class="font-weight-bold blue--text">20글자</span>, '비고' 항목은 최대 <span class="font-weight-bold blue--text">100글자</span>까지 입력 가능합니다.</li>
                                <li style="font: normal normal normal 13px/20px Apple SD Gothic Neo;">지원파일형식은 <span class="font-weight-bold blue--text">엑셀파일(xls, xlsx)</span> 입니다.</li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2" class="text-right">
                    <v-icon x-large>mdi-file-excel</v-icon>
                </v-col>
                <v-col style="font: normal normal bold 13px/20px Apple SD Gothic Neo;">
                    <span>아래의 '찾아보기' 버튼을 클릭해서 첨부할 엑셀 파일을 선택해 주세요.</span><br /><span>1개의 파일만 등록이 가능합니다.</span>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text class="mt-0 pa-3">
            <v-card outlined :disabled="submitFlag">
                <v-card-text class="ma-0 pa-0">
                    <v-simple-table>
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td class="text-center" style="width: 20%; font: normal normal bold 13px/20px Apple SD Gothic Neo; background-color: lightgray">첨부파일</td>
                                <td class="ma-0 pa-0">
                                    <excel-input-card
                                        class="ma-0 pa-0 d-flex overflow-y-auto"
                                        :options="uploadOptions"
                                        @selected="uploadExcelTmp"
                                    >
                                    </excel-input-card>
                                    <v-card flat v-if="attachment.file">
                                        <v-card-text class="ma-0 pl-5 pr-5 pt-0 pb-3">
                                        <v-progress-linear
                                            color="primary"
                                            height="15px"
                                            class="mt-n3 mb-3"
                                            rounded
                                            :indeterminate="progressComp"
                                        >
                                            <strong v-if="!submitFlag">{{ uploadProgress }}%</strong>
                                        </v-progress-linear>
                                        <span class="font-weight-bold" style="padding-bottom: 10px; color: dodgerblue">* 첨부된 파일은 [추가]버튼을 클릭하셔야 등록됩니다.</span>
                                        </v-card-text>
                                    </v-card>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-card-text>
        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-5 pb-6 pt-5 text-center">
                    <v-btn class="ma-2 white--text" width="130px" color="primary" large v-on:click="doSubmit()" :style="submitFlag ? {'opacity': 0.3} : {'opacity': 1}"><span style="font-size:1.4em;">추가</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="grey" large v-on:click="closePopup()" :style="submitFlag ? {'opacity': 0.3} : {'opacity': 1}"><span style="font-size:1.4em;">닫기</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>
<script>

import addrBookApi from '@/api/addr_book'
import { mapState } from 'vuex'

export default {
    components: {
        'excelInputCard': () => import('@/components/addr_book/tile_excel_input')
    },
    data: () => ({
        uploadProgress: 0,
        message: '',
        uploadOptions: {
            'size': {
                'value': 1024 * 1024 * 50,
                'message': '업로드 용량은 50MB 를 초과 할 수 없습니다'
            }
        },
        submitFlag: false
    }),
    computed: {
        ...mapState('addr_book', {
            attachment: state => state.attachment
        }),
        progressComp() {
            return (!this.submitFlag && this.attachment.file && this.uploadProgress != 100) || this.submitFlag ? true : false
        }
    },
    methods: {
        closePopup() {
            if (this.submitFlag) {
                return
            }
            this.$emit('cancel')
        },
        closeAndReload() {
            this.$emit('cancel', true)
        },
        doSubmit() {

            if (this.submitFlag) {
                return
            }

            let ctx = this

            if (this.attachment.tmpFileName && this.attachment.file) {
                this.submitFlag = true

                addrBookApi.createAddrBookBulk(
                    {'tmp_file_name': this.attachment.tmpFileName},
                    (succ_count, fail_count, va) => {
                        ctx.submitFlag = false

                        let msg = '주소록이 등록되었습니다'
                        let subMsg = ''

                        if (fail_count != 0) {
                            subMsg = `(총: ${va.$options.filters.number(succ_count + fail_count)} 건, 성공: ${va.$options.filters.number(succ_count)} 건, 실패: ${va.$options.filters.number(fail_count)} 건)`
                        } else {
                            msg += ` (총: ${va.$options.filters.number(succ_count)} 건)`
                        }

                        ctx.$store.dispatch('openAlert', {
                            'message': msg,
                            'sub_message': subMsg,
                            'closeCb': (va) => {
                                va.closeAndReload()
                            },
                            'va': va
                        })
                    }, (err, va) => {
                        va.submitFlag = false
                        va.$store.dispatch('openAlert', {
                            'message': err
                        })
                    }, this
                )
            } else {
                this.$store.dispatch('openAlert', {
                    'message': '추가할 파일이 없습니다'
                })
            }
        },
        uploadExcelTmp() {
            let ctx = this

            if (this.attachment.file) {
                addrBookApi.uploadExcelTmp(
                    {
                        'file': ctx.attachment.file,
                        'progressEvent': (event) => {
                            ctx.uploadProgress = Math.round((100 * event.loaded) / event.total)
                        }
                    },
                    (data) => {
                        ctx.$store.commit('addr_book/setAttachment', {
                            'tmpFileName': data.tmp_file_name,
                            'file': ctx.attachment.file
                        })
                    },
                    (err) => {
                        ctx.$store.dispatch('openAlert', {
                            'message': '처리 중 오류가 발생하였습니다',
                            'sub_message': err
                        })
                    }
                )
            }
        }
    }
}
</script>
